@tailwind base;
@tailwind components;
@tailwind utilities;


.bg-gradient-redblack{
  background: linear-gradient(to right, #b31217, #000000);

}
.bg-gradient-blackred{
  background: linear-gradient(to right, #000000, #b31217);

}
.bg-gradient-redblack-top{
  background: linear-gradient(to top,#000000 , #b31217)
}


.App{
  background: #000000;
}

@font-face {
  font-family: 'Gotham Medium';
  src: url('./assets/Gotham Medium/Gotham Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

div, h2, h3, p {
  font-family: 'Gotham Medium', sans-serif;
}
@font-face {
  font-family: 'Druk Text Bold';
  src: url('./assets/Druk Wide Family/DrukWide-Heavy-Trial.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

h1 {
  font-family: 'Druk Text Bold', sans-serif;
}

.burger-menu {
  font-family: 'Gotham Medium';
  
}
.bg-reddark {
  background-color: #b31217;
}
.color-reddark {
  color: #b31217;
}