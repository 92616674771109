/* fonts.css */
@font-face {
    font-family: 'Gotham Medium';
    src: url('../../assets/Gotham Medium/Gotham Medium.otf') format('opentype');
    font-weight: medium;
    font-style: normal;
}

.text-gotham {
    font-family: 'Gotham Medium', sans-serif;
}


.custom-padding {
    padding-left: 10rem; /* Adjust padding as needed */
}



@media (max-width: 768px) {
    .custom-padding {
        padding-left: 0rem; /* Adjust padding as needed */
    }

    /*scale down the card on mobile */
    .case-card1{
        transform: scale(0.2);
    }
}
