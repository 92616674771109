.casecard:hover{
    transform: scale(1.05);
    transition: transform 0.5s;
}

.casecard {
    max-width: fit-content;
}


@media screen and (max-width: 768px) {
    .casecard {
        transform: scale(0.9);
    }
    
}